import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import Navigation from '../components/Navigation'
import Vs from '../components/vs'
import location from '../images/location.svg'
import clock from '../images/clock.svg'
import fb from '../images/fb.svg'
import './index.css'

const IndexPage = ({ data }) => (
  <Layout>
    <div className="grid-container">
      <Navigation />
      <div className="logo">
        <Image fixed={data.logo.childImageSharp.fixed} alt="yoyga logo" critical={true} />
      </div>

      <div className="heading">
        <div className="title">Yogya MMA Empire</div>
        <div className="subtitle">India's Mixed Martial Arts Fight League</div>
      </div>
      <div className="titleimage">
        <Image
          className="titleimagecontainer"
          critical={true}
          fluid={data.titleimage.childImageSharp.fluid}
          alt="kicker boxer standing"
        />
      </div>

      <div className="buttons">
        <div className="button">
          <span className="text">
            <Link to="/events">Upcoming Events</Link>
          </span>
        </div>
      </div>

      <div className="red" />
      <div className="black" />
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    logo: file(relativePath: { regex: "/yogya.png/" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    titleimage: file(relativePath: { regex: "/titleimage.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
